import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
  {
    state: '',
    name: 'Check list',
    type: 'saperator',
    icon: 'av_timer',
  },
  //{
  //  state: 'dashboards',
  //  name: 'Dashboards',
  //  type: 'sub',
  //  icon: 'av_timer',
  //  children: [
  //    { state: 'dashboard1', name: 'Dashboard 1', type: 'link' },
  //    { state: 'dashboard2', name: 'Dashboard 2', type: 'link' },
  //  ],
  //},
  {
    state: 'tipichecklist',
    name: 'Tipi Check list',
    type: 'link',
    icon: 'list',
  },
  {
    state: 'quesiti',
    name: 'Quesiti',
    type: 'link',
    icon: 'list',
  },
  {
    state: 'overviewrisposte',
    name: 'Panoramica risposte',
    type: 'link',
    icon: 'border_all',
  },
  {
    state: 'pannellotecnico',
    name: 'Pannello tecnico',
    type: 'link',
    icon: 'border_all',
  },
  {
    state: 'rispostegest',
    name: 'Vis. risposte',
    type: 'link',
    icon: 'list',
  },
  {
    state: '',
    name: 'Configurazioni',
    type: 'saperator',
    icon: 'av_timer',
  },
  {
    state: 'parametri',
    name: 'Config. avanzate',
    type: 'link',
    icon: 'widgets',
  },
  //{
  //  state: 'pages',
  //  name: 'Pages',
  //  type: 'sub',
  //  icon: 'content_copy',
  //  children: [
  //    { state: 'timeline', name: 'Timeline', type: 'link' },
  //    { state: 'invoice', name: 'Invoice', type: 'link' },
  //    { state: 'pricing', name: 'Pricing', type: 'link' },
  //    { state: 'helper', name: 'Helper Classes', type: 'link' },
  //    {
  //      state: 'icons',
  //      name: 'Icons',
  //      type: 'subchild',
  //      subchildren: [
  //        {
  //          state: 'material',
  //          name: 'Material Icons',
  //          type: 'link',
  //        },
  //      ],
  //    },
  //  ],
  //},

];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
