<h1 mat-dialog-title>{{titolo}}</h1>

<mat-dialog-content [formGroup]="form" class="basic-form">
  <div fxLayout="row wrap">
    <!-- column -->
    <div fxFlex.gt-sm="100" fxFlex="100" class="p-10">
      <mat-form-field>
        <mat-label>Nuova password</mat-label>
        <input  type="password" matInput formControlName="newPwd" name="newPwd" required matTooltip="Inserire la nuova password" />
        <mat-error *ngIf="this.form.controls['newPwd'].errors?.['required']">
          Inserire la nuova password
        </mat-error>
        <mat-error *ngIf="this.form.controls['newPwd'].errors?.['pattern']">
          La password deve contenere almeno 8 caratteri di cui almeno una maiusola e un numero
        </mat-error>
      </mat-form-field>
    </div>
    <!-- column -->
    <div fxFlex.gt-sm="100" fxFlex="100" class="p-10">
      <mat-form-field>
        <mat-label>Conferma</mat-label>
        <input  type="password" matInput formControlName="newPwd2" name="newPwd2" required matTooltip="Reinserire la nuova password per conferma" [errorStateMatcher]="matcher"/>
        <mat-error *ngIf="this.form.controls['newPwd2'].errors?.['notSame']">
          Le password non sono uguali
        </mat-error>
      </mat-form-field>
</div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <div align="center">
    <button mat-flat-button (click)="onSaveClick()" tabindex="2" [disabled]="form.invalid" color="accent"  matTooltip="Salva">
      <span class="material-icons">
        save
      </span>
    </button>
    <button mat-flat-button (click)="onCancelClick()" tabindex="-1" color="basic"  matTooltip="Annulla">
      <span class="material-icons">
        undo
      </span>
    </button>
  </div>
</mat-dialog-actions>
