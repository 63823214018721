import {
  ChangeDetectorRef,
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  Inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import { AuthService } from '../../../auth/auth.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginRequest } from '../../../auth/login-request';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { PwdDialogComponent } from './pwd-dialog.component';




@Component({
  selector: 'app-dialogNY',
  template: `<h1 mat-dialog-title>Richiesta conferma</h1>
    <div mat-dialog-content>
      <p>{{data.msg}}</p>
    </div>
    <div mat-dialog-actions>
      <button mat-button  [mat-dialog-close]="data.risp" tabindex="2">Confermo</button> 
      <button mat-button (click)="onNoClick()" tabindex="-1">Annullo</button>
    </div>`,
})
export class DialogYNComponent {
  constructor(
    private dialogRef: MatDialogRef<DialogYNComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  onNoClick(): void {
    this.data.risp = 'N';
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-vertical-sidebar',
  templateUrl: './vertical-sidebar.component.html',
  styleUrls: [],
})
export class VerticalAppSidebarComponent implements OnInit, OnDestroy {
  public config: PerfectScrollbarConfigInterface = {};
  mobileQuery: MediaQueryList;
  userData = {
    userName: '',
    companyName: ''
  }


  @Input() showClass: boolean = false;
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _mobileQueryListener: () => void;
  status = true;
  showMenu = '';
  itemSelect: number[] = [];
  parentIndex = 0;
  childIndex = 0;

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }

  subclickEvent(): void {
    this.status = true;
  }
  scrollToTop(): void {
    document.querySelector('.page-wrapper')?.scroll({
      top: 0,
      left: 0,
    });
  }

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    public dialog: MatDialog,
    private authService: AuthService,
    private router: Router,
    private http: HttpClient,
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.loadUserData();
  }
  ngOnInit(): void {
    
  }
  ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  handleNotify() {
    if (window.innerWidth < 1024) {
      this.notify.emit(!this.showClass);
    }
  }

  loadUserData() {
    var str = localStorage.getItem("userName");
    this.userData.userName = (str === null) ? '' : str;
    str = localStorage.getItem("companyName") 
    this.userData.companyName = (str === null) ? '' : str;
  }

  dlgPwd(): void {
    const dialogRef = this.dialog.open(PwdDialogComponent, {
      height: '300px',
      width: '400px',
      data: {
        titolo: 'Cambio password',
        pwd:'',
        risp: ''
      }
    });



    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.risp == 'Y') {
        let req: LoginRequest = {
          email: '-',
          password: result.pwd
        };

        this.updatePwd(req).subscribe(
          result => {
          }
        );
      }
    });
  }

  updatePwd(req: LoginRequest): Observable<any> {
    var url =environment.baseUrl + 'api/Account/CngPwd/';
    return this.http.post<any>(url, req);
  }

  onLogout(): void {
    const dialogRef = this.dialog.open(DialogYNComponent, {
      width: '250px',
      data: {
        msg: 'Stai per uscire da programma. Confermi?',
        risp: 'Y'
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == 'Y') {
        this.authService.logout();
        this.router.navigate(['/']);
      }
    });
  }
}



export interface DialogData {
  msg: string;
  risp: string;
}
