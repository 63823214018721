import { Component, OnInit } from '@angular/core';
import { FormGroup,  AbstractControl } from '@angular/forms';

@Component({
    template: ''
})
export abstract class BaseFormComponent  {
  form!: FormGroup;

  getErrors(
    control: AbstractControl,
    displayName: string
  ): string[] {
    var errors: string[] = [];
    Object.keys(control.errors || {}).forEach((key) => {
      switch (key) {
        case 'required':
          errors.push('Il dato ' + displayName +' è obbligatorio.');
          break;
        case 'pattern':
          errors.push(displayName +' contiene caratteri non validi.');
          break;
        default:
          errors.push('Il dato '+ displayName +' non è valido.');
          break;
      }
    });
    return errors;
  }

  constructor() { }


}
