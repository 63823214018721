import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { NgMaterialModule } from './angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthInterceptor } from './auth/auth.interceptor';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';



import { RouterModule } from '@angular/router';
//import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
//import { AppRoutes } from './app.routing'; //VEDERE
import { AppComponent } from './app.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';

import { VerticalAppHeaderComponent } from './layouts/full/vertical-header/vertical-header.component';
import { VerticalAppSidebarComponent, DialogYNComponent } from './layouts/full/vertical-sidebar/vertical-sidebar.component';
import { HorizontalAppHeaderComponent } from './layouts/full/horizontal-header/horizontal-header.component';
import { HorizontalAppSidebarComponent} from './layouts/full/horizontal-sidebar/horizontal-sidebar.component';

import { AppBreadcrumbComponent } from './layouts/full/breadcrumb/breadcrumb.component';

//import { DemoMaterialModule } from './demo-material-module'; //dovrebbe essere angular-material.module
//import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { mailService, mailGlobalVariable } from './apps/mailbox/mail.service';
import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
import { MatDialogModule } from '@angular/material/dialog';


import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
//import { MatIconModule } from '@angular/material/icon';
//import { MatToolbarModule } from '@angular/material/toolbar';


import { LoginComponent } from './auth/login.component';
import { HomeComponent } from './home/home.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MylayoutComponent } from './layouts/mylayout/mylayout.component';
import { PwdDialogComponent } from './layouts/full/vertical-sidebar/pwd-dialog.component';
//import { QuesitoDialogComponent } from './int/quesiti/quesito-dialog.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavMenuComponent,
    LoginComponent,
    AppBlankComponent,
    FullComponent,
    VerticalAppHeaderComponent,
    VerticalAppSidebarComponent,
    HorizontalAppSidebarComponent,
    HorizontalAppHeaderComponent,
    AppBreadcrumbComponent,
    SpinnerComponent,
    MylayoutComponent,
    DialogYNComponent,
    PwdDialogComponent,
   // QuesitoDialogComponent
  ],
  exports: [
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    PerfectScrollbarModule,
    SharedModule,
    MatDialogModule,
    MatButtonModule,
    MatCardModule,
    //NgMultiSelectDropDownModule.forRoot(),
    //RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' }),
    HttpClientModule,
    //TranslateModule.forRoot({
    //  loader: {
    //    provide: TranslateLoader,
    //    useFactory: HttpLoaderFactory,
    //    deps: [HttpClient],
    //  },
    //}),
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi:true
    },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } },
    mailService,
    mailGlobalVariable,
    DatePipe,

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
