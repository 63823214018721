import { Component, OnInit,isDevMode } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  isDevelopment = false;
  constructor() { }

  ngOnInit(): void {
    this.isDevelopment = isDevMode();
  }

}
