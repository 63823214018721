import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { HomeComponent } from './home/home.component';

import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { MylayoutComponent } from './layouts/mylayout/mylayout.component';
import { MyhomeComponent } from './int/myhome/myhome.component';
import { TipichecklistComponent } from './int/tipichecklist/tipichecklist.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  {
    path: '',
    component: MylayoutComponent,canActivate:[AuthGuard],
    children: [
      {
        path: 'int',
        loadChildren: () =>
          import('./int/intpages.module').then((m) => m.IntPagesModule),
      },
      //{
      //  path: 'int/myhome',
      //  component: MyhomeComponent, canActivate: [AuthGuard],
      //},
      //{
      //  path: 'int/tipichecklist',
      //  component: TipichecklistComponent, canActivate: [AuthGuard],
      //},
      //{
      //  path: 'material',
      //  loadChildren: () =>
      //    import('./material-component/material.module').then((m) => m.MaterialComponentsModule),
      //},
      //{
      //  path: 'apps',
      //  loadChildren: () => import('./apps/apps.module').then((m) => m.AppsModule),
      //},
      //{
      //  path: 'forms',
      //  loadChildren: () => import('./forms/forms.module').then((m) => m.FormModule),
      //},
      //{
      //  path: 'tables',
      //  loadChildren: () => import('./tables/tables.module').then((m) => m.TablesModule),
      //},
      //{
      //  path: 'tree',
      //  loadChildren: () => import('./tree/tree.module').then((m) => m.TreeModule),
      //},
      //{
      //  path: 'datatables',
      //  loadChildren: () =>
      //    import('./datatables/datatables.module').then((m) => m.DataTablesModule),
      //},
      //{
      //  path: 'pages',
      //  loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
      //},
      //{
      //  path: 'widgets',
      //  loadChildren: () => import('./widgets/widgets.module').then((m) => m.WidgetsModule),
      //},
      //{
      //  path: 'charts',
      //  loadChildren: () => import('./charts/chartslib.module').then((m) => m.ChartslibModule),
      //},
      //{
      //  path: 'multi',
      //  loadChildren: () => import('./multi-dropdown/multi-dd.module').then((m) => m.MultiModule),
      //},
    ],
  },
  {
    path: '',
    component: AppBlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren: () =>
          import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'authentication/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
