<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->
<div class="user-profile" style="background: url(assets/images/background/user-info.jpg) no-repeat">
  <!-- User profile image -->
  <!--<div class="profile-img">
      <img src="assets/images/users/emptyprofile.png" alt="user" (click)="handleNotify()" />
  </div>-->
  <!-- User profile text-->
  <!-- ============================================================== -->
  <!-- Profile - style you can find in header.scss -->
  <!-- ============================================================== -->
  <div class="profile-text">
    <a [matMenuTriggerFor]="sdprofile" class="d-flex align-items-center">
      {{userData.companyName}}<br />
      {{userData.userName}}
      <i class="ti-angle-down font-12 ml-auto"></i>
    </a>
  </div>
  <mat-menu #sdprofile="matMenu" class="mymegamenu">
    <button mat-menu-item (click)="dlgPwd()"><mat-icon>settings</mat-icon> Cambio password</button>
    <button mat-menu-item (click)="onLogout()"><mat-icon>exit_to_app</mat-icon> Logout</button>
  </mat-menu>
</div>

<mat-nav-list appAccordion>
  <mat-list-item appAccordionLink
                 *ngFor="let menuitem of menuItems.getMenuitem()"
                 routerLinkActive="selected"
                 disableRipple
                 group="{{ menuitem.state }}"
                 (click)="scrollToTop()">
    <a class=""
       appAccordionToggle
       [routerLink]="['/int/', menuitem.state]"
       *ngIf="menuitem.type === 'link'"
       (click)="handleNotify()">
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ menuitem.name   }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">
        {{
        badge.value
        }}
      </span>
    </a>

    <a class="" appAccordionToggle href="{{ menuitem.state }}" *ngIf="menuitem.type === 'extLink'">
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ menuitem.name   }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">
        {{
        badge.value
        }}
      </span>
    </a>

    <a class=""
       appAccordionToggle
       href="{{ menuitem.state }}"
       target="_blank"
       *ngIf="menuitem.type === 'extTabLink'">
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ menuitem.name   }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">
        {{
        badge.value
        }}
      </span>
    </a>

    <a class="" appAccordionToggle href="javascript:;" *ngIf="menuitem.type === 'sub'">
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ menuitem.name   }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">
        {{
        badge.value
        }}
      </span>
      <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
    </a>

    <mat-nav-list class="sub-item" *ngIf="menuitem.type === 'sub'">
      <mat-list-item *ngFor="let childitem of menuitem.children; let j = index; "
                     routerLinkActive="selected">
        <a [routerLink]="['/', menuitem.state, childitem.state]"
           *ngIf="childitem.type === 'link'"
           class="relative"
           routerLinkActive="selected"
           (click)="handleNotify()">{{ childitem.name   }}</a>
      </mat-list-item>

      <mat-list-item *ngFor="let childitem of menuitem.children; let j = index; ">
        <a class=""
           href="javascript: void(0);"
           *ngIf="childitem.type === 'subchild'"
           (click)="addExpandClass(childitem.name)"
           [ngClass]="{ selected: showMenu === childitem.name }">
          <span>{{ childitem.name   }}</span>
          <span fxFlex></span>
          <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
        </a>

        <mat-nav-list class="child-sub-item" *ngIf="childitem.type !== 'SubChildren'">
          <mat-list-item *ngFor="let child of childitem.child" routerLinkActive="selected">
            <a [routerLink]="['/', menuitem.state, childitem.state, child.state]"
               routerLinkActive="selected">{{ child.name   }}</a>
          </mat-list-item>
        </mat-nav-list>
      </mat-list-item>
    </mat-nav-list>

    <div class="saperator text-muted" *ngIf="menuitem.type === 'saperator'">
      <span>{{ menuitem.name   }}</span>
    </div>
  </mat-list-item>
</mat-nav-list>

