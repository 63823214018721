<div class="login">
  <h1>Login</h1>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <p>
      <mat-error *ngIf="loginResult && !loginResult.success">
        <strong>Errore: {{loginResult.message}}</strong>
      </mat-error>
    </p>

    <mat-form-field>
      <mat-label> Email:</mat-label>
      <input matInput formControlName="email" required placeholder="email" />
      <mat-error *ngFor="let error of getErrors(this.form.get('email')!,'Email')">{{error}}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label> Password:</mat-label>
      <input matInput type="password" formControlName="password" required placeholder="password" />
      <mat-error *ngFor="let error of getErrors(this.form.get('password')!,'Password')">{{error}}</mat-error>
    </mat-form-field>

    <div>
      <button mat-flat-button color="primary" type="submit">Login</button>
      <button mat-flat-button color="secondary" [routerLink]="['/']">Cancel</button>
    </div>
  </form>
</div>
