<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container"
     [ngClass]="{
    green: green
  }">
  <!-- ============================================================== -->
  <!-- Topbar - style you can find in header.scss -->
  <!-- ============================================================== -->
  <mat-toolbar  class="topbar relative">
    <div class="w-100 fix-width-for-horizontal">
      <!-- ============================================================== -->
      <!-- Logo - style you can find in header.scss -->
      <!-- ============================================================== -->
      <div class="navbar-header">
        <a class="navbar-brand" href="/int/myhome">
          <!-- Logo icon -->
          <b>
            <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
            <!-- Dark Logo icon -->
            <img src="assets/images/logo-icon.png" alt="homepage" class="dark-logo" />
            <!-- Light Logo icon -->
            <img src="assets/images/logo-light-icon.png" alt="homepage" class="light-logo" />
          </b>
          <!--End Logo icon -->
          <!-- Logo text -->
          <span fxShow="false" fxShow.gt-xs>
            <!-- dark Logo text -->
            <img src="assets/images/logo-text.png" alt="homepage" class="dark-logo" />
            <!-- Light Logo text -->
            <img src="assets/images/logo-light-text.png" class="light-logo" alt="homepage" />
          </span>
        </a>
      </div>
      <!-- ============================================================== -->
      <!-- sidebar toggle -->
      <!-- ============================================================== -->

      <button mat-icon-button (click)="snav.toggle()" class="sidebar-toggle" value="sidebarclosed">
        <mat-icon>menu</mat-icon>
      </button> 
      <span fxFlex></span>
      <!-- ============================================================== -->
      <!-- app header component - style you can find in header.scss / header.component.ts-->
      <!-- ============================================================== -->
      <ng-template #horizontalheader>
        <app-horizontal-header></app-horizontal-header>
      </ng-template>


    </div>
  </mat-toolbar>
  <!-- ============================================================== -->
  <!-- End Topbar - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <mat-sidenav-container class="example-sidenav-container"
                         [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
                         [ngClass]="{ minisidebar: minisidebar }">
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <mat-sidenav #snav
                 id="snav"
                 class="pl-xs"
                 [mode]="mobileQuery.matches ? 'side' : 'over'"
                 fixedTopGap="0"
                 [opened]="mobileQuery.matches"
                 [disableClose]="mobileQuery.matches"
                 (open)="sidebarOpened = true"
                 (close)="sidebarOpened = false">
      <perfect-scrollbar 
                         class="scbar"
                         [config]="config">
        <app-vertical-sidebar class="app-sidebar" [showClass]="status" (notify)="snav.toggle()">
        </app-vertical-sidebar>
      </perfect-scrollbar>
    </mat-sidenav>

    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Right Sidebar - style you can find in rightsidebar.component.scss -->
    <!-- ============================================================== -->
    

    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-content class="page-wrapper" [ngClass]="status ? 'minitoggle' : ''">
      <app-breadcrumb></app-breadcrumb>
      <div class="page-container">
        <div class="page-content">
          <router-outlet>
            <app-spinner></app-spinner>
          </router-outlet>
        </div>
      </div>

    </mat-sidenav-content>
    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
  </mat-sidenav-container>
</div>


