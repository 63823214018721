import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginRequest } from '../../../auth/login-request';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control?.invalid && control?.parent?.dirty);
    const invalidParent = !!(control?.parent?.invalid && control?.parent?.dirty);

    return invalidCtrl || invalidParent;
  }
}

export interface PwdDialogData {
  titolo: string;
  pwd: string;
  risp: string
}

@Component({
  selector: 'app-pwd-dialog',
  templateUrl: './pwd-dialog.component.html',
  styleUrls: ['./pwd-dialog.component.scss']
})
export class PwdDialogComponent implements OnInit {
  form!: FormGroup;
  titolo: string;
  fromDialog!: LoginRequest;
  r: any = {
    newPwd: '',
    newPwd2: ''
  };

  matcher = new MyErrorStateMatcher();

  constructor(private fb: FormBuilder,
    private dialogRef: MatDialogRef<PwdDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PwdDialogData
  ) {
    this.titolo = data.titolo;
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      newPwd: new FormControl('', [Validators.required, Validators.pattern(/^(?=.{8,15})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])$/)]),
      newPwd2: new FormControl(0, { validators: [Validators.required] })
    } );


    this.form.patchValue(this.r);
  }

  onSaveClick() {
    var data: PwdDialogData;

    data = {
      titolo: '',
      pwd: this.form.controls['newPwd'].value,
      risp: 'Y',
    }
    this.dialogRef.close(data);
  }

  onCancelClick() {
    var data: PwdDialogData;
    data = {
      titolo: '',
      pwd:'',
      risp: 'N',
    }
    this.dialogRef.close();
  }

  comparePwd(group: FormGroup) {
    let p = group.controls['newPwd'].value;
    let p2 = group.controls['newPwd2'].value;

    return p === p2 ? null : { notSame: true }
  }


}





