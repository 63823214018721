import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, tap } from 'rxjs';
import { environment } from './../../environments/environment';
import { LoginRequest } from './login-request';
import { LoginResult } from './login-result';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(protected http: HttpClient) { }

  private _authStatus = new Subject<boolean>();
  public authStatus = this._authStatus.asObservable();
  public tokenKey: string = "token";

  isAuthenticated(): boolean {
    return this.getStoredValue("token")!==null
  }

  getStoredValue(key: string): string | null {
        return localStorage.getItem(key); // o sessione???
  
    //cmq sarà da cambiare sulla base sella mia risposta...
  }

  private setAuthStatus(isAuthenticated: boolean): void {
    this._authStatus.next(isAuthenticated);
  }

  init(): void {
    if (this.isAuthenticated()) {
      this.setAuthStatus(true);
    }
  }

  login(item: LoginRequest): Observable<LoginResult> {
    var url = environment.baseUrl + "api/Account/LoginMini";
    return this.http.post<LoginResult>(url, item)
      .pipe(tap(loginResult => {
        if (loginResult.success && loginResult.allowedUser) {
          localStorage.setItem("token", loginResult.allowedUser.token);
          localStorage.setItem("userName", loginResult.allowedUser.userName);
          localStorage.setItem("companyName", loginResult.allowedUser.companyName);
          //localStorage.setItem("email", loginResult.allowedUser.email);
        }
      }));
  }



  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("email");
    this.setAuthStatus(false);
  }
}
